module.exports = {
  siteTitle: 'Arun Mohan Raj - Developer, DevOps Engineer',
  siteDescription:
    'Arun Mohan Raj is a Full Stack Developer cum DevOps Engineer at Wipro Digital. He is passionate to learn more and share more.',
  siteKeywords:
    'Arun Mohan Raj, Arun, Arun Mohan, software engineer, Full stack developer, web developer, react, reactjs, DevOps, Jenkins, javascript, Docker, developer, bangalore, salem',
  siteUrl: 'https://arunmohanraj.tech',
  siteLanguage: 'en_US',
  googleVerification: 'SR2qXPi1tfZLFXodkbfrtNEgCjku1OvUbKIQ2zEqVQY',

  name: 'Arun Mohan Raj',
  location: 'Bangalore, IND',
  email: 'arunmohan471@gmail.com',
  github: 'https://github.com/ARUNMOHANRAJ471',
  socialMedia: [
    {
      name: 'Github',
      url: 'https://github.com/ARUNMOHANRAJ471',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/arunmohanraj471/',
    },
    {
      name: 'Medium',
      url: 'https://medium.com/@arunmohanraj471',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/itz_arunmohanraj/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/arunmohanraj1',
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/arunmohanraj471',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '#about',
    },
    {
      name: 'Experience',
      url: '#jobs',
    },
    {
      name: 'Projects',
      url: '#projects',
    },
    // {
    //   name: 'Blog',
    //   url: '#blog',
    // },
    {
      name: 'Education',
      url: '#education',
    },
    {
      name: 'Contact',
      url: '#contact',
    },
  ],

  twitterHandle: '@arunmohanraj1',
  googleAnalyticsID: 'G-LW3LL4G40M',

  navHeight: 100,

  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
